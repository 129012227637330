import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/Header"
import FeaturedProducts from "../components/products/FeaturedProducts"
import Hero from "../components/Hero"
import SEO from "../components/seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO />
      <Header />
      <Hero
        headline={frontmatter.hero_headline}
        description={frontmatter.hero_description}
        buttonLink={frontmatter.hero_button_link}
        buttonText={frontmatter.hero_button_text}
      />
      <FeaturedProducts
        title={frontmatter.featured_product_title}
        categories={frontmatter.featured_product_collections}
      />
      <div className="longform" dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        hero_headline
        hero_description
        hero_button_link
        hero_button_text
        featured_product_title
        featured_product_collections {
          name
          tags
          imgPath {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
