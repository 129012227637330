import React from "react";

const Hero = ({
    headline,
    description,
    buttonLink,
    buttonText
}) => {
    return (
        <div className="hero">
            <div className="hero--inner">
                <h1>{headline}</h1>
                <p>{description}</p>
                <p>
                    <a className="hero--link" href={buttonLink}><span>{buttonText} »</span></a>
                </p>
            </div>
        </div>
    )
};

export default Hero;