import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import _ from "lodash"
import slugify from "slugify"

const FeaturedProducts = ({ title, categories }) => {
  const buildCategoryName = name => {
    if (name.indexOf("Flagstone") !== -1) {
      return <p>{name}</p>
    }
    // find where the plant name ends
    const suffixIndex = name.indexOf(" Plug Trays")
    const plantName = name.slice(0, suffixIndex)
    return (
      <p>
        {plantName}
        <br />
        Plug Trays
      </p>
    )
  }
  const buildCategoryThumbnail = (category, index) => {
    return (
      <div key={index} className="cell medium-3 small-6">
        <Link to={`/${slugify(_.toLower(category.tags[0]))}`}>
          <Img
            fluid={category.imgPath.childImageSharp.fluid}
            className="product-thumb--image__featured"
          />
          <div className="product-thumb--title__featured">
            {buildCategoryName(category.name)}
          </div>
        </Link>
      </div>
    )
  }
  return (
    <div className="grid-x grid-margin-x">
      <h2 className="cell small-12">{title}</h2>
      {_.map(categories, buildCategoryThumbnail)}
    </div>
  )
}

export default FeaturedProducts
